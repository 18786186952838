@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=REM&display=swap");

/* Table all size  */
/* Example small size padding */
.table-small .ant-table-cell {
  padding: 3px 8px !important;
}

/* a:focus {
  background: orange;
} */
/* Example middle size padding */
.table-middle .ant-table-cell {
  padding: 5px 8px !important;
}

/* Example large size padding */
.table-large .ant-table-cell {
  padding: 9px 8px !important;
}

/* tr:nth-child(even) {
  background-color: #FAF9F8;
} */
Input {
  caret-color: #9199ad;
}

.ant-btn-primary {
  box-shadow: none;
  position: relative;
  overflow: hidden;
}

.ant-select-selection-place {
  font-weight: normal;
}

.bordertable table {
  border-collapse: collapse;
  /* Ensures borders are not doubled */
}

.bordertable th,
.bordertable td {
  border: 1px solid black;
  /* Adds a 1px solid black border to table headers and cells */
  padding: 3px;
  /* Adds padding inside table cells */
  margin: 0px;
  /* Ensures no margin inside table cells */
  border-collapse: collapse;
}

.ant-input-disabled {
  color: #4d4d4d !important;
}

/* .ant-btn-primary:before {
  content: "";
  position: absolute;
  height: 100px;
  width: 20px;
  box-shadow: 0 0 15px 12px white;  
  filter: blur(1px);
  opacity: 0.9;
  top: -30px;
  transition: 0.7s;
  transform: rotate(-20deg) translateX(-60px);
}
.ant-btn-primary:hover:before {
  transform: rotate(-20deg) translate(140px, 70px);
} */
/* .customButtonCss.ant-btn-primary:hover {
  box-shadow: inset 2px 2px 3px 1px whitesmoke;
} */
.ca-edit-btn {
  /* cursor: pointer; */
  margin: 0px 4px;
  padding: 2px;
  border-radius: 4px;
  /* border: 1px solid rgb(24, 144, 255, 0.2); */
}

.ca-delete-btn {
  /* cursor: pointer; */
  margin: 0px 4px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #fadbda;
}

.ca-cross-btn {
  margin: 0px 4px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #fadbda;
}

.ca-invoice-btn {
  /* cursor: pointer; */
  margin: 0px 4px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #b0f08f;
}

/* .ca-close-btn:hover {
  color: black;
}
.ca-close-btn {
  color: rgba(0, 0, 0, 0.45);
}
.ca-text-btn {
  padding: 0px 4px;
  font-size: 16px;
  align-items: center;
  height: 25px;
} */

.card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  height: 180px;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* Ensures content is spaced appropriately */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.report-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  height: 205px;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* Ensures content is spaced appropriately */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.report-card:hover {
  /* transform: translateY(-1px); */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  background-color: whitesmoke;
}

.bg-ThemeLight {
  background-color: #eff5ff !important;
}

.ca-active-table2 .ant-table thead tr {
  background-color: red !important;
}

.ca-active-table2 {
  background-color: red !important;
}

/* ---- ConfirmationModal Css ----- */
.ca-confirmationModal {
  /* border: 1px solid red; */
  border-radius: 6px;
  padding: 2px;
  animation-name: example;
  animation-delay: 500ms;
  animation-duration: 1200s;
}

@keyframes example {
  from {
    border: 1px solid red;
  }

  to {
    border: 1px solid red;
  }
}

.grayColor {
  color: gray;
}

.themeColor {
  color: rgb(57, 92, 210) !important;
}

.hoverDiv {
  color: rgb(57, 92, 210);
}

.hoverDiv:hover {
  color: rgb(101, 136, 224);
  cursor: pointer;
}

.themeLightBgColor {
  background-color: rgb(247, 248, 250);
}

.grayLightBgColor {
  background-color: #f7f8fa;
}

.whiteBg {
  background-color: #fff;
}

.grayBg {
  background-color: rgb(203, 203, 203);
}

.darkGrayBg {
  background-color: rgb(248, 248, 248);
}

.selectBgGrayColor .ant-select-selector {
  background-color: rgb(248, 248, 248) !important;
  border: 1px solid rgb(248, 248, 248) !important;
}

.selectBgGrayColor:hover .ant-select-selector {
  background-color: #fff !important;
  border-color: #4096ff !important;
}

/* --------- Table commom --------- */
/* .Tabel-style .ant-pagination-options {
  left: 10px !important;
  position: absolute !important;
} */

/* .Table-font {
  font-family: "REM", sans-serif;
  font-weight: 300;
} */
.Tabel-style {
  padding-top: 10px;
}

/* .Tabel-style
  .ant-pagination
  .ant-pagination-mini
  .ant-table-pagination
  .Tabel1 {
  position: relative !important;
} */

.Tabel-style .ant-table thead tr th {
  background-color: #f5f8ff !important;
  /* color: #253148; */
  color: #4d4d4d;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 600;
}

.Tabel-style .ant-table {
  font-weight: 400;
}

/* --------- Table commom none no style--------- */
.Tabel-style-none .ant-pagination-options {
  left: 0px;
  position: absolute;
}

.Tabel-style-none
  .ant-pagination
  .ant-pagination-mini
  .ant-table-pagination
  .Tabel1 {
  position: relative;
}

.Tabel-style-none .ant-table thead tr th {
  background-color: #f7f8faff !important;
  color: #0b308e;
  font-family: "Open Sans", sans-serif;
}

.Tabel-style-none .ant-table {
  font-weight: 400;
}

/* Profit & loss , Balance Sheet Table css */

.Tabel-style-report
  .ant-table-tbody
  .ant-table-row.ant-table-row-level-0.profit-loss,
.balance-sheet {
  background-color: aliceblue !important;
  font-weight: 600 !important;
}

.Tabel-style-report
  .ant-table-tbody
  .ant-table-row.ant-table-row-level-0.profit-loss-other {
  background-color: rgba(83, 114, 139, 0.095) !important;
  font-weight: 200 !important;
}

.ca-tab-inventory .ant-tabs-nav {
  width: 100% !important;
}

.ca-tab-inventory .antd .ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
  font-size: 16px !important;
  padding: 0px 0px 5px 0px !important;
  /* font-weight: 500; */
  /* justify-content: center !important; */
}

.ca-tab-inventory .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.ca-tab-inventory .ant-tabs-nav {
  margin: 0px !important;
}
.ca-drawer .ant-drawer-body {
  padding: 0 !important;
}
.ca-drawer .ant-drawer-header {
  padding: 5px 10px !important;
}

/* td:nth-child(3) {
  font-weight: 500;
  color: #395cd2;
} */
.icon-bold {
  stroke-width: 25;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-15 {
  font-size: 15px;
}

.fs-12 {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0px !important;
}

.m-8 {
  margin: 8px;
}

.ms-40 {
  margin-left: 40px;
}

.ms-10 {
  margin-left: 10px;
}

.me-0 {
  margin-right: 0px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.my-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-14 {
  padding-top: 14px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.ps-6 {
  padding-left: 6px !important;
}

.ps-32 {
  padding-left: 32px;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ps-18 {
  padding-left: 18px;
}

.ps-14 {
  padding-left: 14px;
}

.ps-10 {
  padding-left: 17px;
}

.ps-11 {
  padding-left: 11px;
}

.pt-2 {
  padding-top: 2px !important;
}

.pe-3 {
  padding-right: 3px !important;
}

.pe-6 {
  padding-right: 6px !important;
}

.pe-10 {
  padding-right: 10px;
}

.pe-15 {
  padding-right: 15px;
}

.pe-17 {
  padding-right: 17px;
}

.pe-12 {
  padding-right: 12px !important;
}

.pe-14 {
  padding-right: 14px !important;
}

.pe-32 {
  padding-right: 32px;
}

.pe-40 {
  padding-right: 40px;
}

.pe-44 {
  padding-right: 44px;
}

.pe-60 {
  padding-right: 60px;
}

.pe-67 {
  padding-right: 67px;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-34 {
  padding-left: 34px;
  padding-right: 34px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.w-130 {
  width: 130px;
}

.w-120 {
  width: 120px;
}

.w-91 {
  width: 91px;
}

.w-65 {
  width: 65px;
}

.w-19 {
  width: 19px;
}

.textEnd {
  text-align: end;
}

.textStart {
  text-align: start;
}

.textCenter {
  text-align: center;
}

.cursorAuto {
  cursor: auto !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-19 {
  padding-top: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pe-42 {
  padding-right: 42px !important;
}

.pe-76 {
  padding-right: 76px !important;
}

.pe-130 {
  padding-right: 130px !important;
}

.hoverLinkColor:hover {
  color: #1677ff !important;
}

.disableColor {
  color: rgb(158, 166, 179);
}

/* For a Template Card */
.template .ant-card-body {
  padding: 0px !important;
}

.templatesec .ant-card-body {
  padding: 15px !important;
}

.ca-card-template .ant-card-body {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* padding-left: 20px !important;
  padding-right: 20px !important; */
}

/* they both are the override class for select dropdown group header and content */
.rc-virtual-list-holder-inner .ant-select-item-group {
  color: rgb(11, 48, 142);
  font-weight: 600;
  font-size: 14px;
}

.rc-virtual-list-holder-inner .ant-select-item-option-content {
  font-size: 13px;
}

/* font family for template */
.TemplateArial * {
  font-family: Arial, Arial Bold Italic, Arial Bold, Helvetica, sans-serif !important;
}

.TemplateCalibri * {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}

.TemplateCambria * {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif !important;
}

.TemplateGeorgia * {
  font-family: Georgia, "Times New Roman", Times, serif !important;
}

.TemplateMyriad * {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

.TemplateThoma * {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.main-invoice {
  width: 100%;
  margin: 0 auto;
  page-break-after: always;
  page-break-before: always;
  font-family: system-ui;
}

.main-invoice-space {
  padding: 6px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  min-height: 842px;
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .pb-10 {
    padding-bottom: 10px;
  }
}
