.w-50 {
  width: 50% !important;
}
.invoice-padd-5 {
  padding: 4px 8px;
}
.invoice-logo-content {
  display: flex;
}
.invoice-logo {
  position: relative;
  width: 100px;
  height: 100px;
}
.break-all {
  word-break: break-all;
}
.invoice-fnt-11 {
  font-size: 11px;
}

table {
  width: 100%;
}

.temp3table tbody > tr > td {
  border: 1px solid;
  border-collapse: collapse;
}
.temp3table thead > tr > th {
  border: 1px solid;
}
