.main-invoice {
  width: 770px;
  margin: 0 auto;
  page-break-after: always;
  page-break-before: always;
  font-family: system-ui;
}
p {
  line-height: 1.2rem;
}
div p:nth-last-of-type(1),
p {
  padding: 0;
  margin-bottom: 0;
}
.invoice-fnt-11 {
  font-size: 11px;
}
.main-invoice-space p {
  font-weight: 500;
}
.invoice-lh-normal {
  line-height: inherit;
}
.invoice-logo {
  width: 98px;
  height: 98px;
  object-fit: cover;
}
.invoice-padd-5 {
  padding: 5px 8px;
}
.invoice-fnt-11 {
  font-size: 11px;
}
.invoice-heading-fnt-16 {
  font-size: 16px;
}
.invoice-second-table table {
  color: #000;
  width: 100%;
}
.invoice-second-table table thead th {
  font-size: 11px;
  padding: 10px 5px;
  text-align: center;
  border: 1px solid;
}
.invoice-second-table table,
.invoice-third-table {
  width: 100%;
  border-collapse: collapse;
}
.invoice-third-table table {
  border-top: none;
  color: #000;
  width: 100%;
}
.main-inner-bank-detail {
  border: 1px solid #000;
  margin: 5px 0;
  display: flex;
}
.main-invoice-space p {
  font-weight: 500;
}
.invoice-lh-inherit {
  line-height: inherit;
}

.invoice-fnt-10 {
  font-size: 10px;
  color: #000;
}
.m-b {
  margin-bottom: 5px;
}

.invoice-second-table table,
.invoice-third-table {
  width: 100%;
  border-collapse: collapse;
}
.invoice-third-table table thead th {
  font-size: 11px;
  padding: 10px 5px;
  border-top: 0;
}
.invoice-second-table table td,
.invoice-third-table table td {
  font-size: 11px;
  padding: 3px 5px;
  text-align: right;
  vertical-align: top;
  font-weight: 500;
}
.qr-section {
  border: 1px solid #000;
  display: flex;
}
.qr-section-tax-inner p {
  padding: 1px 0;
}
.qr-section-tax {
  border-right: 1px solid #000;
}
.main-invoice-space p {
  font-weight: 500;
}
.bank-tax-detail {
  border-right: 1px solid #000;
  border-collapse: collapse;
}
.conditions table tr td:first-child {
  width: 29%;
}
.conditions table td {
  font-size: 11px;
  font-weight: 400;
  border: 0px;
}
.temp2table tbody > tr > th {
  border: 1px solid;
  /* color: red; */
  border-collapse: collapse;
}
.temp2table tbody > tr > td {
  border: 1px solid;
  line-height: 1.3;
  /* color: greenyellow; */
  border-collapse: collapse;
}
p {
  margin: 0;
  font-weight: 400;
  color: #000;
}
b,
strong {
  font-weight: 600;
}
b,
strong {
  font-weight: bolder;
}
